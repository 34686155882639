/*
 * @Descripttion: *
 * @version: *
 * @Author: Fengshi
 * @Date: 2020-11-20 14:45:19
 * @LastEditors: Fengshi
 * @LastEditTime: 2020-11-20 14:46:03
 */
import AgentForm from './AgentForm.vue'
export default AgentForm
