/*
 * @Descripttion: *
 * @version: *
 * @Author: Fengshi
 * @Date: 2020-11-20 14:38:21
 * @LastEditors: Mingkit
 * @LastEditTime: 2021-01-06 14:48:25
 */
import AgentForm from './AgentForm'
export default {
  name: 'AgentAdd',
  components: {
    AgentForm
  },
  computed: {
    isAdd () {
      const path = this.$route.path
      if (/add/.test(path)) {
        return true
      } else {
        return false
      }
    }
  },
  async beforeMount () {
    if (!this.isAdd) {
      const data = await this.$wPost('/admin/agent/get.do', { id: this.$route.params.id })
      console.log(data)
      this.$refs['agent-form'].updateForm(data)
    }
  }
}
