/*
 * @Descripttion: *
 * @version: *
 * @Author: Fengshi
 * @Date: 2020-11-20 14:57:42
 * @LastEditors: Mingkit
 * @LastEditTime: 2021-01-06 14:50:16
 */
import HandleData from '@/service/Agent'
import { cloneDeep } from 'lodash'
export default {
  name: 'AgentForm',
  data () {
    return {
      form: {
        agent_name: '',
        user_list: []
      },
      options: [],
      rules: {
        agent_name: [{
          validator (rule, val, res) {
            if (val === '') {
              res('请填写代理商名称')
            }
            if (val.length > 50) {
              res('代理商名称不超过50个字')
            }
            res()
          },
          trigger: 'blur',
          required: true
        }]
      }
    }
  },
  methods: {
    async getUserList (keyword) {
      const data = await this.$wPost('/admin/agent/search_user.do', {
        keyword,
        agent_id: this.form.agent_id
      })
      this.options = data
    },
    leaderToggle (res) {
      console.log(res)
      this.form.user_list = res
    },
    submit () {
      this.$refs.form.validate(v => {
        if (v) {
          if (this.form.agent_id) {
            this.editSubmit()
          } else {
            this.addSubmit()
          }
        } else {
          this.$refs.submitButton.updatedLoading(false)
        }
      })
    },
    async addSubmit () {
      const payload = new HandleData(this.form).add()
      const data = await this.$wPost('/admin/agent/add.do', payload)
      if (data === true) {
        this.$wToast.success('新建成功')
        this.$router.push({ path: '/admin/agent' })
      }
      this.$refs.submitButton.updatedLoading(false)
    },
    async editSubmit () {
      const payload = new HandleData(this.form).edit()
      const data = await this.$wPost('/admin/agent/update.do', payload)
      if (data === true) {
        this.$wToast.success('修改成功')
        this.$router.replace({ path: `/admin/agent/detail/${this.form.agent_id}` })
      }
      this.$refs.submitButton.updatedLoading(false)
    },
    cancel () {
      history.go(-1)
    },
    updateForm (payload) {
      this.form = cloneDeep(payload)
    }
  }
}
