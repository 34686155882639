/*
 * @Descripttion: *
 * @version: *
 * @Author: Fengshi
 * @Date: 2020-11-20 14:38:38
 * @LastEditors: Fengshi
 * @LastEditTime: 2020-11-20 14:41:33
 */
import AgentAdd from './AgentAdd.vue'
export default AgentAdd
